<template>
<div class="section-wrap">
    <v-container class="page">
        <noscript>
            <iframe src='//20835400p.rfihub.com/ca.html?rb=44778&ca=20835400&_o=44778&_t=20835400&ra=YOUR_CUSTOM_CACHE_BUSTER' style='display:none;padding:0;margin:0' width='0' height='0'>
            </iframe>
        </noscript>
        <noscript>
            <img src="https://ad.doubleclick.net/ddm/activity/src=1524815;type=petin0;cat=petin004;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
        </noscript>
        <v-row>
            <v-col cols="12">
                <v-card elevation="0">
                    <v-card-title>
                        <v-row class="pa-2" >
                            <v-col cols="12" md="6">
                                <span :class="[classTogglleOnSpan]" id="congrats" >
                                    <span ref="congrats-heading-step-5" tabindex="-1" id="congrats-heading-step-5" role="heading" aria-level="1">Congratulations</span>
                                    <small class="d-block text-h6">  You did it! Thank you for making your pet's health a priority.</small>
                                </span>
                            </v-col>
                            <v-col cols="12" md="6" role="region">
                                <span class="subtitle-1 d-block" aria-label="Your policy confirmation or confirmations should be emailed to you immediately. If you have any questions about your policy; feel free to give us a call. At this point your order is done, you can print,  exit or review your Order details Bellow.">
                                    Your policy confirmation(s) should be emailed to you immediately.
                                    <br>
                                    If you have any questions about your policy; feel free to give us a call:
                                    <span> <a :aria-label="'Call number '+ this.getPartnerContactInfo() +' now'" :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEventPhone()"> {{this.getPartnerContactInfo()}} </a> </span>
                                    <!--6388  <span v-else> <a class="link_outline" aria-label="Call number 855-270-738 now" href="tel:855-270-7387"> 855-270-PETS (7387) </a></span> -->
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-actions>
                        
                        <v-row>
                            <v-col cols="12" class="d-flex justify-space-between align-center d-flex flex-wrap">
                                <span id="Print_descrition" class="d-sr-only">Activating this button opens the system print dialog from where you can print the document.</span>
                                <v-btn class="cca_text" aria-describedby="Print_descrition" id="printPage" ref="printPage"   text @click="printPage">Print this Page</v-btn>
                                <v-btn class="cca_text" aria-describedby="Save_Description" id="saveRecords" ref="saveRecords"  text @click=" printrecords">Save for Records</v-btn>
                                <span id="Save_Description" class="d-sr-only">Activating this button opens the system print dialog from where you also save the document as pdf, using print to pdf option.</span>
                                <v-btn class="cca_text" aria-label="Leave petfirst's quote funnel now." text @click="exitApp">Exit</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col> 
        </v-row>
        <v-row class="d-flex flex-wrap">
            <v-col class="family-header" cols="12" v-if="isFamilyPlan">
                        <v-card-title id="familyPlanConfirmation" class="">
                                <v-row class="d-flex align-center justify-center">
                                    <v-col cols="12" class="d-flex flex-row">
                                        <PetView v-for="pet in petInfo" :key="pet.id" :aria-label="pet.name + 'Policy.'" :isChallenger="true" :isSimpleLayout="false" :petDetails="pet" :isCurrent="false" :showDetails="true" :isEditable="false"></PetView>
                                    </v-col>  
                                </v-row>
                            </v-card-title>
                            <v-divider role="none"></v-divider>
                </v-col>
            <v-col cols="12" md="7" class="form-col-section" v-if="!isFamilyPlan">
                <v-card class="mt-10"  flat v-for="(plan,index) in selectedPlans" :key="plan.planRate" role="region" :aria-label="'Policy for'+petInfo[index].name" >
                    <!-- Optimization: Removed PepperJam Script -->
                    <!-- <iframe :src="'https://t.pepperjamnetwork.com/track?INT=DYNAMIC&PROGRAM_ID=9175&ORDER_ID=' + plan.PolicyNumber + '&ITEM_ID1=sale&ITEM_PRICE1=' + quoteTotals[0].total + '&QUANTITY1=1&CLICK_ID=' +clickId" title="Policy Plan" width="1" height="1" frameborder="0"></iframe> -->
                    <v-card-title :id="'planTitle'+index" >
                        <v-row class="d-flex flex-column align-center justify-center">
                            <span class="d-sr-only" role="heading" aria-level="2">{{"Policy for-"+petInfo[index].name}} </span>
                            <h2 class="primary--text text-uppercase confirmation-title-h2"> <span v-if="selectedPlans.length === 1">Your policy for </span> {{ petInfo[index].name }}</h2>

                            <v-col cols="auto" class="flex-grow-1 d-flex align-center justify-center py-0">
                                <PetView :aria-label="petInfo[index].name + 'Policy.'" :petDetails="petInfo[index]" :isCurrent="false" :isSimpleLayout="true" :isChallenger="false" :showDetails="true" :isEditable="false"></PetView>
                            </v-col>
                            <v-col cols="auto" class="d-flex flex-column py-1">
                                Policy Number: {{ plan.PolicyNumber.replace(/^PFH/, '') }}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider role="none" ></v-divider>
                    <v-card-text  role="region" aria-label="Policy Details">
                        <v-row class="order-details-grid">
                            <v-col class="py-2" cols="12">
                                <v-row no-gutters class="d-flex justify-space-between">
                                    <v-col cols="5" class="font-weight-bold">Your Rate:</v-col>
                                    <v-col cols="7"> {{plan.planRate}} </v-col>
                                </v-row>
                                <v-divider role="none"></v-divider>
                            </v-col>
                            <v-col class="py-2" cols="12">
                                <v-row no-gutters class="d-flex justify-space-between">
                                    <v-col cols="5"  class="font-weight-bold">Annual Limit:</v-col>
                                    <v-col cols="7"> {{ reformatPrice ( plan.annualLimit, true) }}</v-col>
                                </v-row>
                                <v-divider role="none"></v-divider>
                            </v-col>
                            <v-col class="py-2" cols="12">
                                <v-row no-gutters  class="d-flex justify-space-between">
                                    <v-col cols="5"  class="font-weight-bold">Reimbursement:</v-col>
                                    <v-col cols="7"> {{ plan.reimbursement}}</v-col>
                                </v-row>
                                <v-divider  role="none"></v-divider>
                            </v-col>
                            <v-col class="py-2" cols="12">
                                <v-row no-gutters  class="d-flex justify-space-between">
                                    <v-col cols="5"  class="font-weight-bold">Deductible:</v-col>
                                    <v-col cols="7"> {{ reformatPrice (plan.deductible, true) }}</v-col>
                                </v-row>
                                <v-divider role="none"></v-divider>
                            </v-col>
                            <v-col class="py-2" cols="12" role="none" v-if="showWellnessSteteRestricted(partner.id, owner.state)">
                                <v-row no-gutters  class="d-flex justify-space-between" role="row">
                                    <v-col cols="5"  class="font-weight-bold" role="rowheader">{{routineCareLabel}}:</v-col>
                                    <v-col cols="7" v-if="plan.routineCare === 'None'" role="cell">No</v-col>
                                    <v-col cols="7" v-else-if="plan.routineCare === 'Standard Wellness'" role="cell">Yes</v-col>
                                    <v-col cols="7" v-else-if="plan.routineCare === 'Wellness 365'" role="cell">Preventive 365</v-col>
                                    <v-col cols="7" v-else-if="plan.routineCare === 'Wellness 575'" role="cell">Preventive 575</v-col>
                                    <v-col cols="7" v-else role="cell"> {{ reformatCurrencyString (plan.routineCare, true) }}</v-col>
                                </v-row>
                                <v-divider  role="none"></v-divider>
                            </v-col>
                            <v-col class="py-1" cols="12">
                                <v-row no-gutters class="d-flex justify-space-between">
                                    <v-col cols="5"  class="font-weight-bold">Accident Coverage Begins:</v-col>
                                    <v-col cols="7">  {{plan.accidentalCoverageDate}}</v-col>
                                </v-row>

                            </v-col>
                            <v-col class="py-1" cols="12" md="12" >
                                <v-row no-gutters class="d-flex justify-space-between">
                                    <v-col cols="5"  class="font-weight-bold">Illness Coverage Begins:</v-col>
                                    <v-col cols="7">{{plan.illnessCoverageDate}}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="7" class="form-col-section" v-else>
                <!-- Optimization: Removed PepperJam Script -->
                <!-- <iframe :src="'https://t.pepperjamnetwork.com/track?INT=DYNAMIC&PROGRAM_ID=9175&ORDER_ID=' + familyPlan[0].PolicyNumber + '&ITEM_ID1=sale&ITEM_PRICE1=' + quoteTotals[0].total + '&QUANTITY1=1&CLICK_ID=' +clickId" title="Order" width="1" height="1" frameborder="0"></iframe> -->
                <v-card flat>
                    <v-card-title id="familyPlanTitle" >
                        <v-row class="d-flex align-center justify-center">
                            <span class="d-sr-only" role="heading" aria-level="2">Policy for your pets </span>
                            <v-col cols="12" class="d-flex flex-column justify-center align-center">
                                <h2 class="primary--text text-uppercase confirmation-title-h2">Your Family Plan</h2>
                                <div class="pet-avatar max-width-none">
                                    <ul class="pet-details horizontal-list body-1 flex-row ml_darkergray--text">
                                        <li v-for="pet in petInfo" key="pet.id"> {{pet.name}}</li>
                                    </ul>
                                </div>
                            </v-col>
                            <v-col cols="auto" class="d-flex flex-column py-1">
                                Policy Number: {{ familyPlan[0].PolicyNumber.replace(/^PFH/, '') }}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider role="none" ></v-divider>
                    <v-card-text role="region" aria-labelledby="familyPlanTitle">
                        <v-row>
                            
                        </v-row>
                        <v-row class="order-details-grid" >
                            <v-col class="py-2" cols="12">
                                <v-row class="d-flex justify-space-between">
                                        <v-col cols="5" class="font-weight-bold">Your Rate:</v-col>
                                        <v-col cols="7">{{familyPlan[0].planRate}} </v-col>
                                    </v-row>
                                    <v-divider role="none"></v-divider>
                                </v-col>
                                <v-col class="py-2" cols="12">
                                    <v-row class="d-flex justify-space-between">
                                        <v-col cols="5" class="font-weight-bold">Annual Limit:</v-col>
                                        <v-col cols="7"> {{ reformatPrice (familyPlan[0].annualLimit , true) }}</v-col>
                                    </v-row>
                                    <v-divider role="none"></v-divider>
                                </v-col>
                                <v-col class="py-2" cols="12">
                                    <v-row  class="d-flex justify-space-between">
                                        <v-col cols="5" class="font-weight-bold">Reimbursement:</v-col>
                                        <v-col cols="7">{{familyPlan[0].reimbursement}}</v-col>
                                    </v-row>
                                    <v-divider role="none"></v-divider>
                                </v-col>
                                <v-col class="py-2" cols="12">
                                    <v-row class="d-flex justify-space-between">
                                        <v-col cols="5" class="font-weight-bold">Deductible:</v-col>
                                        <v-col cols="7"> {{ reformatPrice (familyPlan[0].deductible, true) }}</v-col>
                                    </v-row>
                                    <v-divider role="none"></v-divider>
                                </v-col>
                                <v-col class="py-2" cols="12" v-if="showWellnessSteteRestricted(partner.id, owner.state)">
                                    <v-row class="d-flex justify-space-between">
                                        <v-col cols="5" class="font-weight-bold">{{routineCareLabel}}:</v-col>
                                        <v-col cols="7" v-if="familyPlan[0].routineCare === 'None'">No</v-col>
                                        <v-col cols="7" v-else-if="familyPlan[0].routineCare === 'Standard Wellness'"> Yes </v-col>
                                        <v-col cols="7" v-else-if="familyPlan[0].routineCare === 'Wellness 365'" role="cell">Preventive 365</v-col>
                                        <v-col cols="7" v-else-if="familyPlan[0].routineCare === 'Wellness 575'" role="cell">Preventive 575</v-col>
                                        <v-col cols="7" v-else> {{ reformatCurrencyString (familyPlan[0].routineCare , true) }}</v-col>
                                    </v-row>
                                    <v-divider role="none"></v-divider>
                                </v-col>
                                <v-col class="py-2" cols="12">
                                    <v-row class="d-flex justify-space-between">
                                        <v-col cols="5" class="font-weight-bold">Accident Coverage Begins:</v-col>
                                        <v-col cols="7"> {{familyPlan[0].accidentalCoverageDate}}</v-col>
                                    </v-row>
                                    <v-divider role="none"></v-divider>
                                </v-col>
                                <v-col class="py-2" cols="12">
                                    <v-row class="d-flex justify-space-between">
                                        <v-col cols="5" class="font-weight-bold">Illness Coverage Begins:</v-col>
                                        <v-col cols="7"> {{familyPlan[0].illnessCoverageDate}}</v-col>
                                    </v-row>
                                    <v-divider role="none"></v-divider>
                                </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="5" class="side-col-section">
                <v-card color="ml_lightgray" outlined elevation="0"  class="side-card">
                    <v-container>
                        <v-row>
                            <v-col cols="10" offset="1" class="text-center">
                                <v-card-title>
                                    <v-img 
                                        alt="Snoopy"
                                        class="shrink side-card-img"
                                        contain
                                        :src="step4SideImgSource"
                                        transition="scale-transition"
                                        max-height="212"
                                        width="auto"
                                    />
                                </v-card-title>
                                <v-card-text aria-live="polite" aria-atomic="true" role="region" aria-labelledby="breakdownRegionLabel">
                                    <div class="quote-breakdown d-flex flex-column">
                                        <v-row no-gutters class="quote-section">
                                            <v-col cols="7" class="quote-text text-left"> Total Premium </v-col>
                                            <v-col cols="5" class="quote-value">
                                                <span class="d-sr-only">Total Premium, </span> {{ reformatPrice(quoteTotals[0].subTotal) }}
                                            </v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                        <div v-if="partner.billingOptions == 'payrollDeduct' || payOption == 'payrollDeduct'">
                                            <div v-for="(fee, index) in sortedFees(this.policies[0].plan.paymentSchedule.feeTotalBreakdown)" :key="index">
                                                <v-row no-gutters class="quote-section" v-if="fee.amount !== 0">
                                                    <v-col cols="7" class="quote-text text-left" aria-label="Fees,"> {{ getFeesShortDescription(fee.description) }} </v-col>
                                                    <v-col cols="5" class="quote-value">
                                                        <span class="d-sr-only">Fees, </span> + {{ 
                                                            !isFamilyPlan && petsCount > 1 
                                                                ? owner.state === 'GA' 
                                                                    ? reformatPrice((fee.amount / 11) * petsCount) 
                                                                    : reformatPrice((fee.amount / 12) * petsCount)
                                                                : owner.state === 'GA' 
                                                                    ? reformatPrice(fee.amount / 11) 
                                                                    : reformatPrice(fee.amount / 12)
                                                        }}
                                                    </v-col>
                                                </v-row>
                                                <v-divider role="none"></v-divider>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <v-row no-gutters class="quote-section" v-if="quoteTotals[0].fees !== 0">
                                                <v-col cols="7" class="quote-text text-left" aria-label="Fees,"> {{ getFeesLabel() }} </v-col>
                                                <v-col cols="5" class="quote-value">
                                                    <span class="d-sr-only">Fees, </span> + {{ reformatPrice(quoteTotals[0].fees) }}
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div v-show="quoteTotals[0].taxes != 0">
                                            <v-divider role="none"></v-divider>
                                            <v-row no-gutters class="quote-section">
                                                <v-col cols="7" class="quote-tex text-left" aria-label="Taxes,"> Taxes </v-col>
                                                <v-col cols="5" class="quote-value">
                                                    <span class="d-sr-only">Taxes, </span> + {{reformatPrice(quoteTotals[0].taxes)}}
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <v-divider role="none"></v-divider>
                                        <v-row no-gutters class="quote-section" v-if="quoteTotals[0].discounts !== 0">
                                            <v-col cols="7" class="quote-text text-left" aria-label="Discounts,"> Discounts </v-col>
                                            <v-col cols="5" class="quote-value">
                                                <span class="d-sr-only">Discounts, </span> - {{reformatPrice(quoteTotals[0].discounts)}}
                                            </v-col>
                                        </v-row>
                                        <v-divider role="none"></v-divider>
                                        
                                        <v-row no-gutters class="quote-total mt-3">
                                            <v-col cols="12" class="quote-text text-left font-weight-bold"> Your Total </v-col>
                                            <v-col cols="12" class="quote-value mt-4">
                                                <div class="price-value xxl">
                                                    <span class="currency font-weight-bold"> {{  getPriceSplit( quoteTotals[0].total )[0] }} </span>
                                                    <span class="units font-weight-bold">    {{  getPriceSplit( quoteTotals[0].total )[1] }} </span>
                                                    <span class="cents font-weight-bold d-flex flex-column align-start">    
                                                        <span>{{  getPriceSplit( quoteTotals[0].total )[2] }} </span>
                                                        <span class="payment-frequency d-flex flex-column align-start pl-2">{{' ' + paymentFrequency}} <br/> Premium</span>
                                                    </span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <!-- <span class="ml_blue--text d-flex flex-row justify-center align-center"> <v-icon class="ml_green--text mt-n1"> mdi-check </v-icon>{{ this.getDiscounstApplied() }}</span> -->
                                    <div class=" text-left d-flex flex-row justify-start align-start" v-for="discount in getSavedDiscountsData()" :key="discount.discountId">
                                            <v-icon class="ml_green--text mt-n1 mr-1"> mdi-check </v-icon> <span class="ml_gray--text text--darken-4">{{ discount.name }}</span>
                                    </div>
                                </v-card-text>
                                <v-card-actions class="d-flex flex-column black--text" v-if="!employeeToken">
                                    <v-btn block x-large color="ml_purple" class="nextBtnFocus" @click="completeAccountSetUp()">
                                        Complete My Pets <br> Account Setup
                                        <v-icon small>mdi-play</v-icon>
                                    </v-btn>
                                    <p class="pt-3 pb-7 support-phone">Or call: <a :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEventPhone()"> {{this.getNumbertoAlphanumberic(this.getPartnerContactInfo())}}</a></p>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>   
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-divider role="none"></v-divider>

                <v-card flat class="mt-5" role="region">
                    <v-card-title role="heading" aria-level="2">Don't Forget The MetLife Pet Mobile App
                    </v-card-title>
                    <v-card-subtitle>
                        Download the MetLife Pet App for easy claims submission, tracking and more!
                    </v-card-subtitle>
                    <v-card-text class="text--primary">
                        <div>
                            If you are a MyBenefits user, you can use your MyBenefits credentials to log in
                        </div>
                        <div>
                            If you don't have a MyBenefits ID, no worries! You can use the email you provided during enrollment to log in.
                        </div>
                        <v-row>
                            <v-col cols="12" sm="6" class="d-flex justify-center">
                                <a 
                                    aria-label="Apple Store App Link" 
                                    class="externalLinkFocuseable" 
                                    href="#"
                                    v-on:click="
                                    redirectToOtherLink(
                                        'https://t.sidekickopen87.com/s3t/c/5/f18dQhb0S7kF8c7_HhW54W18n2zGCwVN8Jbw_8QsRtKVngStR8r4Q-lW16gGCD3yrx-B101?te=W3R5hFj4cm2zwW3zfPTB1JwJ3FW3ZV6nw43T4P9W4cgyMR45lYhZW4fKvX83JF53VW4ff5ZK1Q4Xkbw24VKlS4kb2&si=8000000002997545&pi=deb6d1da-4168-4b4d-a732-d0e84f358a0e'
                                    )"
                                    v-on:keyup.enter="
                                    redirectToOtherLink(
                                        'https://t.sidekickopen87.com/s3t/c/5/f18dQhb0S7kF8c7_HhW54W18n2zGCwVN8Jbw_8QsRtKVngStR8r4Q-lW16gGCD3yrx-B101?te=W3R5hFj4cm2zwW3zfPTB1JwJ3FW3ZV6nw43T4P9W4cgyMR45lYhZW4fKvX83JF53VW4ff5ZK1Q4Xkbw24VKlS4kb2&si=8000000002997545&pi=deb6d1da-4168-4b4d-a732-d0e84f358a0e'
                                    )"
                                    target="_blank"
                                >
                                    <v-img alt="apple store link"
                                    class="shrink mr-2"
                                    contain
                                    :src= imgApple
                                    transition="scale-transition"
                                    width="200px" />
                                </a>
                            </v-col>
                            <v-col cols="12"  sm="6"  class="d-flex justify-center">
                                <a 
                                    aria-label="Android Play Store App Link" 
                                    class="externalLinkFocuseable"  
                                    href="#"
                                    v-on:click="
                                    redirectToOtherLink(
                                        'https://t.sidekickopen87.com/s3t/c/5/f18dQhb0S7kF8c7_HhW54W18n2zGCwVN8Jbw_8QsRtKVngStR8r4Q-lW16gGCD3yrx-B101?te=W3R5hFj4cm2zwW3zfPTB1JwJ3FW3ZV6nw43T4P9W4cgyMR45lYhZW4fKvX83JF53VW4ff5ZK1Q4Xkbw24VKlS4kb2&si=8000000002997545&pi=deb6d1da-4168-4b4d-a732-d0e84f358a0e'
                                    )"
                                    v-on:keyup.enter="
                                    redirectToOtherLink(
                                        'https://t.sidekickopen87.com/s3t/c/5/f18dQhb0S7kF8c7_HhW54W18n2zGCwVN8Jbw_8QsRtKVngStR8r4Q-lW16gGCD3yrx-B101?te=W3R5hFj4cm2zwW3zfPTB1JwJ3FW3ZV6nw43T4P9W4cgyMR45lYhZW4fKvX83JF53VW4ff5ZK1Q4Xkbw24VKlS4kb2&si=8000000002997545&pi=deb6d1da-4168-4b4d-a732-d0e84f358a0e'
                                    )"
                                    target="_blank"
                                >
                                <v-img alt="play store link"
                                class="shrink mr-2"
                                contain
                                :src=imgPlay
                                transition="scale-transition"
                                width="200px" />
                                </a>

                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>
<script>
    import PetView from '../challenger/shared/pet.vue';
    import VueCookies from 'vue-cookies';

    import orderSummary from '../global/js/orderSummary';
    export default {
        mixins: [orderSummary],
        components: { PetView },
        computed: {
            availableDiscounts: function () {
                return this.$store.state.discounts.options;
            },
            savedDiscounts: function () {
                return this.$store.state.discounts.selection;
            },
            employeeToken: function () {
                return this.$store.getters.employeeToken;
            },
            step4SideImgSource(){
                return this.$store.getters.getStep4SideImgSource;
            },
        },
        mounted(){
            this.delay(2000).then(() => this.runInvoca());
        },
        methods: {
            completeAccountSetUp() {
                this.$emit("completeAccountSetUp");
            },
            trackAdobeEventPhone() {
                let ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: this.getPartnerContactInfo(),
                        leadId: this.leadId,
                        stepName: 'Summary'
                    }
                };
                digitalData.eventTrack('linkClick', ldo);
            }, 
            getSavedDiscountsData(){
                return this.availableDiscounts.filter( discount => {
                    return this.savedDiscounts.includes(discount.discountId);
                })
            },
            async redirectToOtherLink(url) {
                sessionStorage.clear();
                this.$store.reset();

                window.location.href = url;
            }
        }
    }
</script>

<style>
    .outline:focus {
        outline: 3px solid;
        outline-offset: 4px;
        outline-color: #007ABC;
    }

    .v-btn:focus.primary {
        outline-color: #005481;
    }

    .v-btn:focus.cca_text {
        outline-color: #007ABC;
    }

    .cca_text {
        color: #007abc !important;
        caret-color: #007abc;
    }

    .cca_text:focus {
        color: #005481 !important;
    }
    .link_outline{
        color: #007abc;
    }
    .link_outline:focus{
        padding: 10px;
        outline: 3px solid #007abc;
    }
</style>
<style>
    .max-width-none {
        max-width: none !important;
    }
</style>
    